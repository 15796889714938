"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.someSeries = exports.someLimit = exports.some = exports.reduceRight = exports.reduce = exports.mapSeries = exports.mapLimit = exports.map = exports.filterSeries = exports.filterLimit = exports.filter = exports.everySeries = exports.everyLimit = exports.every = exports.forEachOfSeries = exports.forEachOfLimit = exports.forEachOf = exports.forEachSeries = exports.forEachLimit = exports.forEach = exports.findSeries = exports.findLimit = exports.find = void 0;
const async = __importStar(require("async"));
const iterableMethodsFactory_1 = require("./iterableMethodsFactory");
exports.find = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.detect);
exports.findLimit = (0, iterableMethodsFactory_1.iterableLimitMethodsFactory)(async.detectLimit);
exports.findSeries = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.detectSeries);
exports.forEach = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.each);
exports.forEachLimit = (0, iterableMethodsFactory_1.iterableLimitMethodsFactory)(async.eachLimit);
exports.forEachSeries = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.eachSeries);
exports.forEachOf = (0, iterableMethodsFactory_1.iterableEachOfMethodsFactory)(async.eachOf);
exports.forEachOfLimit = (0, iterableMethodsFactory_1.iterableEachOfLimitMethodsFactory)(async.eachOfLimit);
exports.forEachOfSeries = (0, iterableMethodsFactory_1.iterableEachOfMethodsFactory)(async.eachOfSeries);
exports.every = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.every);
exports.everyLimit = (0, iterableMethodsFactory_1.iterableLimitMethodsFactory)(async.everyLimit);
exports.everySeries = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.everySeries);
exports.filter = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.filter);
exports.filterLimit = (0, iterableMethodsFactory_1.iterableLimitMethodsFactory)(async.filterLimit);
exports.filterSeries = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.filterSeries);
exports.map = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.map);
exports.mapLimit = (0, iterableMethodsFactory_1.iterableLimitMethodsFactory)(async.mapLimit);
exports.mapSeries = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.mapSeries);
exports.reduce = (0, iterableMethodsFactory_1.iterableMemoMethodsFactory)(async.reduce);
exports.reduceRight = (0, iterableMethodsFactory_1.iterableMemoMethodsFactory)(async.reduceRight);
// export const reject = iterableMethodsFactory(async.reject);
// export const rejectLimit = iterableLimitMethodsFactory(async.rejectLimit);
// export const rejectSeries = iterableMethodsFactory(async.rejectSeries);
exports.some = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.some);
exports.someLimit = (0, iterableMethodsFactory_1.iterableLimitMethodsFactory)(async.someLimit);
exports.someSeries = (0, iterableMethodsFactory_1.iterableMethodsFactory)(async.someSeries);
// May be some issues because of the 2nd arg accumulartor
// export const transform = iterableMemoMethodsFactory(async.transform);
