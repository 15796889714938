"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.iterableMemoMethodsFactory = exports.iterableLimitMethodsFactory = exports.iterableEachOfLimitMethodsFactory = exports.iterableEachOfMethodsFactory = exports.iterableMethodsFactory = void 0;
/**
 * A factory that turns functions (both fully concurrent and fully in series)
 * from the async library into methods/handlers
 * for LDflex
 * @param asyncFunction The function to turn into a method/handler for LDflex
 */
function iterableMethodsFactory(asyncFunction) {
    return class {
        handle(pathData, path) {
            return (parameterFunction) => new Promise((resolve, reject) => {
                asyncFunction(path, ((item, callback) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const result = yield parameterFunction(yield Promise.resolve(item));
                        // eslint-disable-next-line callback-return
                        callback(null, result);
                    }
                    catch (e) {
                        // eslint-disable-next-line callback-return
                        callback(e);
                    }
                })), (err, res) => __awaiter(this, void 0, void 0, function* () {
                    if (err)
                        reject(err);
                    else
                        resolve(Array.isArray(res) ? yield Promise.all(res) : res);
                }));
            });
        }
    };
}
exports.iterableMethodsFactory = iterableMethodsFactory;
/**
 * A factory that turns functions (both fully concurrent and fully in series)
 * from the async library into methods/handlers for LDflex
 * Use specifically for EachOf methods
 * @param asyncFunction The function to turn into a method/handler for LDflex
 */
function iterableEachOfMethodsFactory(asyncFunction) {
    return class {
        handle(pathData, path) {
            return (parameterFunction) => new Promise((resolve, reject) => {
                asyncFunction(path, ((item, key, callback) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const result = yield parameterFunction(yield Promise.resolve(item), key);
                        // eslint-disable-next-line callback-return
                        callback(null, result);
                    }
                    catch (e) {
                        // eslint-disable-next-line callback-return
                        callback(e);
                    }
                })), (err, res) => __awaiter(this, void 0, void 0, function* () {
                    if (err)
                        reject(err);
                    else
                        resolve(res);
                }));
            });
        }
    };
}
exports.iterableEachOfMethodsFactory = iterableEachOfMethodsFactory;
/**
 * A factory that turns functions with a limited concurrency
 * from the async library into methods/handlers
 * for LDflex
 * Use specifically for EachOf methods
 * @param asyncFunction The function to turn into a method/handler for LDflex
 */
function iterableEachOfLimitMethodsFactory(asyncFunction) {
    return class {
        handle(pathData, path) {
            return (parameterFunction, limit = 5) => new Promise((resolve, reject) => {
                asyncFunction(path, limit, ((item, key, callback) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const resolvedItem = yield item;
                        const result = yield parameterFunction(resolvedItem, key);
                        // eslint-disable-next-line callback-return
                        callback(null, result);
                    }
                    catch (e) {
                        // eslint-disable-next-line callback-return
                        callback(e);
                    }
                })), (err, res) => __awaiter(this, void 0, void 0, function* () {
                    if (err)
                        reject(err);
                    else
                        resolve(res);
                }));
            });
        }
    };
}
exports.iterableEachOfLimitMethodsFactory = iterableEachOfLimitMethodsFactory;
/**
 * A factory that turns functions with a limited concurrency
 * from the async library into methods/handlers
 * for LDflex
 * @param asyncFunction The function to turn into a method/handler for LDflex
 */
function iterableLimitMethodsFactory(asyncFunction) {
    return class {
        handle(pathData, path) {
            return (parameterFunction, limit = 5) => new Promise((resolve, reject) => {
                asyncFunction(path, limit, ((item, callback) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const resolvedItem = yield item;
                        const result = yield parameterFunction(resolvedItem);
                        // eslint-disable-next-line callback-return
                        callback(undefined, result);
                    }
                    catch (e) {
                        // eslint-disable-next-line callback-return
                        callback(e);
                    }
                })), (err, res) => __awaiter(this, void 0, void 0, function* () {
                    if (err)
                        reject(err);
                    else
                        resolve(Array.isArray(res) ? yield Promise.all(res) : res);
                }));
            });
        }
    };
}
exports.iterableLimitMethodsFactory = iterableLimitMethodsFactory;
/**
 * A factory that turns functions (both fully concurrent and fully in series)
 * from the async library into methods/handlers
 * for LDflex
 * Used specifically for methods with memo's i.e. reducers
 * @param asyncFunction The function to turn into a method/handler for LDflex
 */
function iterableMemoMethodsFactory(asyncFunction) {
    return class {
        handle(pathData, path) {
            return (parameterFunction, memo) => new Promise((resolve, reject) => {
                asyncFunction(path, memo, ((_memo, item, callback) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const result = yield parameterFunction(_memo, yield Promise.resolve(item));
                        // eslint-disable-next-line callback-return
                        callback(undefined, result);
                    }
                    catch (e) {
                        // eslint-disable-next-line callback-return
                        callback(e);
                    }
                })), (err, res) => __awaiter(this, void 0, void 0, function* () {
                    if (err)
                        reject(err);
                    else
                        resolve(Array.isArray(res) ? yield Promise.all(res) : res);
                }));
            });
        }
    };
}
exports.iterableMemoMethodsFactory = iterableMemoMethodsFactory;
